/* src/assets/styles/Contact.css */
.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items at the start */
  align-items: center;
  padding: 20px;
  text-align: center;
  background-color: var(--background-color);
  border-radius: 60px;
  margin: 20px auto; /* Center the container with margin */
  box-sizing: border-box;
  width: 80%;
}

.contact-container h2 {
  margin-bottom: 20px;
  color: var(--primary-color);
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 600px; /* Make the form wider */
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-group label {
  margin-bottom: 5px;
  color: var(--primary-color);
}

.form-group input,
.form-group textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
}

.form-group textarea {
  resize: vertical;
}

button[type="submit"] {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button[type="submit"]:hover {
  background-color: var(--secondary-color);
}

.contact-info {
  margin-top: 20px;
  text-align: center; /* Centered contact info */
}

.contact-info h3 {
  color: var(--primary-color);
}

.contact-info p {
  margin: 5px 0;
  color: var(--text-color);
}

.contact-info a {
  color: var(--primary-color);
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.alert {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert-failed {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

@media (max-width: 768px) {
  .contact-container {
    width: 95%;
    min-height: auto; /* Adjust for mobile view */
  }
}

@media (min-width: 769px) {
  .contact-container {
    min-height: auto; /* Ensure the container does not take full height */
  }
}

@media (orientation: landscape) {
  .contact-container {
    min-height: auto; /* Ensure container stretches in landscape orientation */
  }
}