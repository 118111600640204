/* src/assets/styles/ProjectCard.css */
.project-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .project-card:hover {
    transform: translateY(-5px);
  }
  
  .project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .project-details {
    padding: 20px;
  }
  
  .project-details h3 {
    margin-top: 0;
  }
  
  .project-links {
    margin-top: 15px;
    display: flex;
    gap: 10px;
  }
  
  .project-links a {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #333;
    text-decoration: none;
    font-weight: bold;
  }
  
  .project-links a:hover {
    color: #007BFF;
  }