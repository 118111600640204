/* src/assets/styles/Skills.css */
.skills-container {
  padding: 50px 20px;
  background-color: #007BFF;
  margin-top: 50px;
}

.skills-container h2 {
  text-align: center;
  margin-bottom: 40px;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.skill-category {
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 8px;
}

.skill-category h3 {
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
}

.skill-category ul {
  list-style: none;
  padding: 0;
}

.skill-category li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.skill-category li svg {
  margin-right: 8px;
  color: #007BFF;
}