/* src/assets/styles/Header.css */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #333;
  color: #fff;
}

.header-container h1 {
  margin: 0;
  font-size: 24px;
}

.nav {
  display: flex;
  gap: 20px;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  position: relative;
}

.nav-link.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.mobile-menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 200px;
    background-color: #333;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: right 0.3s ease-in-out;
  }

  .nav.active {
    right: 0;
  }

  .mobile-menu-icon {
    display: block;
  }
}