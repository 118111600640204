/* src/assets/styles/Projects.css */
.projects-container {
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.projects-container h2 {
  text-align: center;
  margin-bottom: 40px;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}