/* src/assets/styles/Home.css */
.home-container {
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hero-text {
  flex: 1;
  min-width: 300px;
}

.hero-text h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 18px;
  margin-bottom: 30px;
}

.cta-button {
  padding: 10px 20px;
  margin-bottom: 20px;
  background-color: #007BFF;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
}

.cta-button:hover {
  background-color: #0056b3;
}

.hero-image {
  flex: 1;
  max-width: 500px;
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 20px;
}